// Typography
// Contains the generic theme typography styles

// BASE TYPOGRAPHY

//@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Titillium+Web:300,400,700');

/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/roboto-condensed-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
       url('fonts/roboto-condensed-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/roboto-condensed-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/roboto-condensed-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('fonts/roboto-condensed-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/roboto-condensed-v18-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed 400 - latin*/
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/roboto-condensed-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
       url('fonts/roboto-condensed-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/roboto-condensed-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/roboto-condensed-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/roboto-condensed-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/roboto-condensed-v18-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Titillium Web';
    src: url('fonts/TitilliumWeb-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('fonts/TitilliumWeb-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('fonts/TitilliumWeb-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Web';
    src: url('fonts/TitilliumWeb-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('fonts/TitilliumWeb-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;

}

@font-face {
    font-family: 'Titillium Web';
    src: url('fonts/TitilliumWeb-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  src: url('fonts/MaterialSymbolsOutlined-Regular.ttf') format('truetype');
}



body {
    //font-size: 13px;
    //line-height: 20px;
    //margin-bottom: 20px;
    //color: #333333;
    font-family: $font-titi;

    //$font-family-base

    a:link {
        -webkit-tap-highlight-color: $blue; // this sets the highlight color when links are tapped on Safari (browser) on iPhone
    }
}

::selection,
::-moz-selection { /* Applies style to highlighted portion of a page */
    background: $blue;
    color: #fff;
    text-shadow: none;
}

.typography {

    color: $body-text;
    font-weight: 300;

    // HEADERS

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-roboto;
        font-weight: 500;
        margin-bottom: 12px;
    }

    h1 {
        @include unitize(font-size, 34px);
        margin-bottom: 16px;
    }
    h2 {
        @include unitize(font-size, 27px);
    }
    h3 {
        @include unitize(font-size, 23px);
    }
    h4 {
        @include unitize(font-size, 18px);
    }
    h5 {
        @include unitize(font-size, 17px);
    }
    h6 {
        @include unitize(font-size, 15px);
    }

    // PARAGRAPHS

    p {}
    .intro {}
    em { font-style: italic; }
    strong {
        font-weight: 700;
    }

    // LINKS

    a,
    a.intro {
        outline: none;
    }
    a:hover {}
    a:focus {}

    // LIST STYLES
    ul,
    ol,
    dl {}
    ul {
        li {
        }
    }

    // TABLE STYLES

    //table {
    //    border-collapse: collapse; // borders are collapsed into a single border when possible
    //    border: 1px solid #d4d4d4;
    //    border-spacing: 0; // the border-spacing property sets the distance between the borders of adjacent cells - acts as a backup to border-collapse: collapse
    //    margin: 0 0 10px;
    //    text-align: left;
    //}
    //table tr:nth-child(even) {
    //    background-color: #ededed
    //}
    //table tr.even,
    //table th,
    //thead td {
    //    background-color: #ededed
    //}
    //table td,
    //table th {
    //    padding: 2px 5px;
    //    border: 1px solid #d4d4d4;
    //    vertical-align: top;
    //}
    //table th {
    //    font-weight: bold;
    //}

    // WYSIWYG EDITOR ALIGNMENT CLASSES

    .left {
        text-align: left;
    }
    .center {
        text-align: center;
    }
    .right {
        text-align: right;
    }

    // IMAGES

    img {
        //border: 5px solid #d7d7d7;
        height: auto; // resets the image height so that it maintains its aspect ratio when width is set
        //background: transparent url(../img/ajax-loader.gif) no-repeat center center;

        &.left {
            float: left;
            max-width: 50%;
            margin: 5px 20px 10px 0;
        }
        &.right {
            float: right;
            max-width: 50%;
            margin: 5px 0 10px 20px;
        }
        &.leftAlone {
            float: left;
            margin-right: 100%;
            margin-bottom: 10px;
            clear: both;
        }
        &.center {
            float: none;
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-bottom: 10px;
            clear: both;
        }
		&.page-icon {
			height: 38px;
		}
		&.function-icon{
			height: 30px;
		}
    }

    .captionImage {
        width: 100%;
        margin-top: 5px;

        img {
            margin: 0;
        }
        &.left {
            float: left;
            margin: 5px 30px 5px 0;
            width: 40%;
        }
        &.right{
            float: right;
            margin: 5px 0 5px 30px;
            width: 40%;
        }
		&.gallery{
			float: left;
			margin: 5px 0px 5px 0;
			width: 20%;		
		}
        &.left[style],
        &.right[style] {
            max-width: 40%; // overrides core width to make responsive
        }
        &.left,
        &.right {
            img {
                float: none;
                max-width: none;
                width: 100%;
            }
        }
        &.left img {
            margin-right: -10px;
        }
        &.right img {
            margin-left: -10px;
        }
        &.right p {
            margin-left: -10px;
            text-align: left;
        }
        &.leftAlone{
            float:none;
            margin: 0 20px 5px 0;
        }
        &.center{
            margin: 0 auto 20px;
        }
        p {
            clear: both;
            margin: 5px 0;
            font-style: italic;
            color: #888;
        }
        figure {
            margin: 0;
        }
        .caption {
            color: $body-text;
            margin: 5px 0 !important;
            @include unitize(font-size, 12px);
        }
    }

    // BLOCKQUOTES

    //blockquote {
    //    background: transparent url(../img/blockquote.png) no-repeat 0 6px;
    //    color: #777;
    //    display: block;
    //    font-style: italic;
    //    margin: 0 0 20px 5%;
    //    float: right;
    //    text-indent: 30px;
    //    width: 50%;
    //    clear: both;
    //
    //    p {
    //        font-size: 17px;
    //        line-height: 25px;
    //    }
    //}
    blockquote {
        color: $block-text;
        font-style: italic;
        font-weight: 400;
        border-left: 2px solid $block-text;
        @include unitize(font-size, 24px);
        @include unitize(margin-left, 20px);
        @include unitize(padding-left, 20px);
    }

    pre {
        background: #F7F7F7;
        border: 1px solid #E4E4E4;
        margin: 0 0 20px 0;
        padding: 15px;
        clear: both;
    }

    // ADDRESS

    address {
        display: block;
        margin-bottom: 20px;
    }
}
