// Widgets
// Only define 3rd party widgets (e.g. weather widget) here.

.news-widgets {
    &-sidebar {
        background-color: $lighten-gray;
        color: #fff;

        section {
            h3 {
                @include unitize(font-size, 17px);
            }
            a {
                color: #fff;
                font-family: $font-roboto;
                @include unitize(font-size, 14px);
                @include unitize(padding, 5px 5px 5px 0);

                &:hover {
                    color: #a3b7d5;
                }

                .fa-angle-right {
                    color: $section-border;
                    @include unitize(margin-right, 4px);
                }
            }
        }
        .section-border-bottom {
            border-bottom: 1px solid $section-border;
        }
    }
}