// Footer
// Only define footer elements here.

footer {
    background: $dark-green url('../img/bg/bg-dark.png') repeat;

    .footer {
        background-color: transparent;
        .row {
            margin: 0;
        }

        &-brand-big {
            margin-left: -15px;
            display: block;
            padding: 15px;
            background-color: #fff;
            border-bottom-right-radius: 170px;
            color: #000;
            height: 100%;
            white-space: inherit;
            width: 290px;
			font-size:1.25rem;
			line-height: 22px;
            img {
                float: left;
            }
        }
        &-brand-small {
            margin-left: -15px;
            display: block;
            padding: 15px;
            background-color: #fff;
            border-bottom-right-radius: 150px;
            color: #000;
            height: 100%;
            white-space: inherit;
            width: 150px;
            line-height: 14px;
            font-size: 70%;
            img {
                margin-bottom: 10px;
            }
        }

        &-liste {

			justify-content: flex-end;
			display: flex;
			align-items: flex-end;
            &-logo {
                margin:15px 0;
            }

            &-links {
                display: block;
                font-size: .8125em;
				text-align:right;
                color: #fff;
				margin-bottom: 0.2rem;
                a {
                    color: #fff;
                }
            }
        }
        &-copyright {
            font-size: .8125em;
            background-color: $body-text;
            opacity: 0.5;
            text-align: center;
            height: 30px;
            line-height: 30px;
            color: #fff;
        }
    }
}