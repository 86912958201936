// Responsive
// Only define responsive rules here.

//@media screen and (max-width: $desktop-max) {
//    .container {
//        max-width: 1260px;
//    }
//}



//@media screen and (min-width: $xxl-desktop-min) {
//  html, body {
//    font-size: 20px;
//  }
//}
//
//@media screen and (max-width: $xl-desktop-max) {
//  html, body {
//    font-size: 18px;
//  }
//}
//
//@media screen and (max-width: $desktop-max) {
//  html, body {
//    font-size: 16px;
//  }
//}
//


					  
	 
   
//large screen
@media screen and (min-width: $tablet-portrait-min) {
	.mobile-only {
		display: none;
	}
	.small-screen-hide {
		display: none;
	}
	.mobile-only .aktuelles-liste{
		padding:0;
	}
	.card-body .card-text-sm.forum a{
		padding-left:30px;
	}

  .navbar-row-top-second .nav-link {
    margin: 0 4px;
    width: 5.7rem;
    height: 30px;
    text-align: right;
    line-height: 14px;
   }
  .navbar-row-top-second .nav-link div {
    float: left;
    margin-top: -5px;
  }
  .navbar-row-top-second .nav-link:first-child {
    width: 2rem;
  }
	.navbar-social-links a.nav-link{
			padding: 8px 7px;
			@include unitize(font-size, 20px);
            svg {
                margin-top: 9px;
            }
			img {
				height: 17.5px;
			}
	}
	.embargo-static-png{
		display: block; 
		margin-left: auto; 
		margin-right: auto; 
		width: 50%;
	}
}

//uebergangs groesse
@media screen and (max-width: $tablet-portrait-max) {

					  
	 
  
  .hide-mobile {
    display: none;
  }

    // main menu in mobile
    .navbar-nav-main {
      background-color:transparent;
      width:100%;
      font-weight:300;
      font-size:.9rem;
    }
    .navbar-nav-main .main-pulldown-menu li a{
      color: #343a43;
      text-align:left;
      display:block;
      margin:0px;
      padding:5px;
    }
    .navbar-nav-main li{
      background-color:white;
      list-style-position:inside;
      text-align:left !important;
      padding: 0rem 0rem 0rem 1rem; 
      -webkit-transition: height .25s ease .1s; 
      -moz-transition: height .25s ease .1s; 
      -o-transition: height .25s ease .1s; 
      -ms-transition: height .25s ease .1s; 
      transition: height .25s ease .1s;
      border-bottom: 1px solid #e8e8e8;
    }
    .navbar-nav-main ul.main-pulldown-menu{
      padding-top:10px;
      background-color:white;
      width:100%;
      align-items:normal;
    }
    .navbar-nav-main .main-pulldown-menu ul{
      position:static;
    }
    .dropdown-menu {
      background-color:white;
      padding: 0;
    }
    .navbar-nav-main .main-pulldown-menu a.dropdown-item {
      font-weight:300;
      border:none;
      padding: 0.3rem 1rem;
      background-color:#eceff4;
    }
    .navbar-nav-main .dropdown-menu{
      background-color:#eceff4;
    }
    .navbar-nav-main .dropdown-menu{
      margin:0;
    }
    .navbar-row-top-second {
      overflow: hidden;
    }  
    .navbar-row-top-second .nav-link {
      width: 4rem;
     }
    .navbar-row-top-third {
      width: 100%;
      height: 40px;
      line-height: 40px;
    }
    .color-set {
      &-ol {
        &.navbar-nav .nav-item .nav-link{
          color:black;
        }
      }
      &-mtb-o {
        &.navbar-nav .nav-item .nav-link{
          color:black;    
        }
      }
      &-trail-o {
        &.navbar-nav .nav-item .nav-link{
          color:black;    
        }
      }
      &-ski-ol {
        &.navbar-nav .nav-item .nav-link{
          color:black;    
        }
      }
      &-allgemein {
        &.navbar-nav .nav-item .nav-link{
          color:black;    
        }
      }
    } // .color-set close
  
  }

// small screen
@media screen and (max-width: $phone-landscape-max) {

.hide-in-mobile {
  display: none;
}
  .navbar-brand{
	width:120px;
 }
  .navbar-brand div{
	line-height: 14px;
	font-size: 70%;
  }
  .navbar-row-top-second .nav-link {
    margin: 0 4px;
    width: 4rem;
    height: 30px;
    text-align: center;
    line-height: 14px;
   }
	.img-fluid-small{
		max-width:100%;
	}
}
//
@media screen and (max-width: $phone-portrait-max) {

  .navbar-row.navbar-top-links.navbar-row-top-second .nav-link.btn-nav{
    width:4.4rem;
    justify-content:center;
    display:flex;
  }
  .navbar-row-top-second .nav-link {
    transform: rotate(-50deg);
    margin: 0 -28px;
    width: 100px;
    height: 30px;
    text-align: center;
    line-height: 15px;
   }
  .navbar-row-top-second .nav-link:first-child {
    transform: rotate(0deg);
  }
  .navbar-row-top-second .nav-link:last-child {
    margin-right: -5px;
   }

  .footer {
    &-liste {

      &-links {
        padding-right: 40px;
        a {
          display: block;
        }
        a:last-child {
          margin-bottom: 10px;
        }
        span {
          display: none;
        }
      }
    }
  }
  .longterm .card img{
	width:150px;
	padding-top: 1rem !important
  }
}
//
//@media screen and (max-width: $flip-phone-max) {
//  html, body {
//    font-size: 7px;
//  }
//}
//
//@media screen and (max-width: $super-ultra-tiny-flip-phone-max) {
//  html, body {
//    font-size: 5px;
//  }
//}
.navbar-collapse.show .main-pulldown-menu .nav-item a.nav-link{
	color:#000;
}
															   
			
 

