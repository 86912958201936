// Blocks
//  Only define blocks (e.g. header, events list, cards) here.
// and Kader/Trainer Boxes
.card {
    border: 1px solid $pattens-grey;
    display: inline-block;
	padding: 1.25rem;
	width: 100%;
    @include unitize(margin-bottom, 30px);
	
	&-list{
		border: 1px solid $pattens-grey;
		border-radius: .25rem;
		background-clip: border-box;
		padding: 1.25rem;
		background-color:#fff;
		@include unitize(margin-bottom, 30px);
	}
    &-body {
		padding:0rem;
        &-border {
            border-bottom: 1px solid $light-gray;
        }
        &-bottom {
            @include unitize(padding, 8px 20px);
        }
        .card {
            &-text {
                &-date {
                    @include unitize(font-size, 13px);
                }
                &-sm {
                    color: $block-text;
                    font-weight: 300;
                    @include unitize(font-size, 14px);
					&.forum{
						color: $forum-text;
					}
                }
            }
            &-item {
                &-category,
                &-name {
                    font-weight: 700;
                    @include unitize(font-size, 15px);
                }
                &-date,
                &-link {
                    @include unitize(font-size, 14px);
                }
            }
        }
        .fa-angle-right {
            vertical-align: middle;
        }
        a {
            &:hover {
                text-decoration: none;
            }
        }
        .img-thumbnail {
            border-radius: 0;
            padding: 0;
        }
    }
    &-category {
        background: #fff;
        position: absolute;
        text-align: center;
        @include unitize(height, 40px);
        @include unitize(left, 0);
        //@include unitize(padding, 5px);
        @include unitize(top, -40px);
    }
    &-columns {
        column-count: 2;
    }
    &-deck {
        &-news {
            .card {
                background: none !important;
                border: none !important;
                &-body {
                    @include unitize(padding, 15px 0);
                    &-bottom {
                        @include unitize(font-size, 14px);
                    }
                }
                &-text {
                    color: $block-text;
                    font-weight: 300;
                    @include unitize(font-size, 14px);
                }
                .text {
                    &-dark {
                        font-family: $font-roboto;
                        @include unitize(font-size, 17px);
                    }
                    &-muted {
                        color: $text-muted;
                        @include unitize(font-size, 13px);
                    }
                }
                h3 {
                    font-family: $font-roboto;
                    font-weight: 700;
                    @include unitize(font-size, 17px);
                }
            }
        }
    }
    &-header {
        background: none;
		border-bottom:none;
		padding:0rem;
        //border-bottom: 1px solid $whisper;
        //border-left: 6px solid $light-black;
        color: $light-black;
        font-weight: 500;
        line-height: 35px;
        @include unitize(font-size, 25px);
        //@include unitize(min-height, 57px);
        @include unitize(min-height, 25px);
        &.small {
            @include unitize(font-size, 13px);
        }
		h3 {
			@include unitize(font-size, 17px);
		}
    }
    .card {
        // 05.01.2020 KK background: $pattens-grey;
		border: none;
        @include unitize(margin, 0 8px);

        .card-header {
            background: $pattens-grey;
            border-bottom: 1px solid #fff;
            border-left: none;
        }
        &-img-overlay {
            bottom: 0;
            height: 47px;
            top: auto;
            @include bg-rgba($block-overlay, 0.6);
            h5 {
                @include unitize(font-size, 17px);
                .fa-angle-right {
                    margin-top: 2px;
                }
            }
        }
    }
    &-news {
        &-list {
			&.card{
				&.blog{
					padding-left:40px;
					.card-deck {
						padding: 0 15px 0 0px;
						margin-left:-21px;
					}
				}
				width:100%;
			}
            article.card {
                @include border-radius(0);
                @include unitize(margin-bottom, 20px);
                &:after {
                    content: ' ';
                    // 10.02.2025 KK border-bottom: 1px solid $light-gray;
                    bottom: 0;
                    position: absolute;
                    @include unitize(left, -20px);
                    @include unitize(right, -20px);
                }
            }
            .card-deck {
                padding: 0 15px 0 23px;
				margin-left:-10px;
				.msg-no-post{
					margin-left:5px;
				}
                &-news {
                    overflow: hidden;
                }
            }
            .category-icon {
                color: $light-black;
                font-weight: 700;
                text-align: center;
                text-transform: uppercase;
                @include unitize(height, 40px);
                @include unitize(line-height, 40px);
                @include unitize(width, 46px);
            }

            // calculate cards per line for each breakpoint
            $cards-per-line: (
                xs: 1,
                sm: 3,
                md: 4,
                lg: 4,
                xl: 4
            );
            $grid-gutter-width-custom: 18px !default;
            @each $name, $breakpoint in $grid-breakpoints {
                @media (min-width: $breakpoint) {
                    .card-deck {
                        .card {
                            flex: 0 0 calc(#{100/map-get($cards-per-line, $name)}% - #{$grid-gutter-width-custom});
                        }
                    }
                }
            }
            &-one-col {
                $cards-per-line: (
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1
                );
                $grid-gutter-width-custom: 18px !default;
                @each $name, $breakpoint in $grid-breakpoints {
                    @media (min-width: $breakpoint) {
                        .card-deck {
                            .card {
                                flex: 0 0 calc(#{100/map-get($cards-per-line, $name)}% - #{$grid-gutter-width-custom});
                            }
                        }
                    }
                }
            }
        }
    }
}
.kader-box{
	display:flex;
	align-items:center;
	padding:10px;
}
.kader-box h4.list-topic{
	margin-bottom:4px;
}
.border-box{
	border:1px #E3E7EA solid;
}
.card-list .card.bg-dark{
	border: none;
	padding:.5rem;
	@include unitize(margin, 0 8px);
}
.card-body .schwarzes-brett.card-text{
                &-date {
                    @include unitize(font-size, 15px);
                }
                &-sm {
                    color: $block-text;
                    font-weight: 300;
                    @include unitize(font-size, 15px);
                }
}
// longterm articles on homepage
.longterm .card {             
		background: none !important;
		border: none !important;
		&-body {
			@include unitize(padding, 15px 0);
			&-bottom {
				@include unitize(font-size, 14px);
			}
		}
		&-text {
			color: $block-text;
			font-weight: 300;
			@include unitize(font-size, 14px);
		}
		.text {
			&-dark {
				font-family: $font-roboto;
				@include unitize(font-size, 17px);
			}
			&-muted {
				color: $text-muted;
				@include unitize(font-size, 13px);
			}
		}
		h3 {
			font-family: $font-roboto;
			font-weight: 700;
			@include unitize(font-size, 17px);
		}
}
// accordion - bootstrap cards clash with accordion cards
.accordion .card{
	background: #f8f9fa;
	border: none;
	border-radius: 2px;
	padding: 0;
}
.accordion .card .card-header{
	background: #f8f9fa;
	border: none;
	border-radius: 2px;
	padding: 0;
	min-height:25px;	
	min-height:1em;	
}

.accordion .card-header button{
	background: none;
	border: none;
	padding: 0;
	text-decoration: none;
}
.accordion .card-header .btn{
	background: none;
	border: none;
	text-decoration:none;
}
.accordion-wrapper{
	background: #fff;
}
.accordion .card .card-header .btn:after {
	font-family: "Material Symbols Outlined" !important;
	content:"\e698";
	font-size:150%;
    font-weight: 900;
	margin-top: -0.2rem;
    float: right;
}
.accordion .card .card-header .btn.collapsed:after {
	font-family: "Material Symbols Outlined" !important;
	content:"\e69b";
    font-weight: 900;
	font-size: 150%;
	margin-top: -0.2rem;
    float: right;
}



