// Base
// Only define base elements (e.g. html, a, img) here.

html, body {
    background: #fff url('../img/bg/bg-light-2.png') repeat;
}

#wrapper{
	margin:0 auto; 
	max-width:1140px;
	padding:0;
}

//html, body {
//  background-color: $white;
//  color: $black;
//  font-family: sans-serif;
//  font-size: 16px;
//  font-weight: normal;
//  height: 100%;
//  line-height: 1.5;
//  position: relative;
//}

//*, *:after, *:before {
//  box-sizing: border-box;
//}

a {
	color: $link-blue;
}
//a, a:hover {
//  color: inherit;
//  text-decoration: none;
//}
//
//img {
//  max-width: 100%;
//}
a.small-link{
	font-size:14px;
	font-weight:normal;
	text-decoratio:none;
}
a.btn-link{
	color:white;
	font-size:14px;
	font-weight:normal;
	text-decoratio:none;
}
a.btn-link:hover{
	color:white;
	background-color: #0f93b7;
	border-color: #0e8aab;
	text-decoration:none;
}
button.btn-sm.btn-btn-link{
	padding:.1rem 1.3rem;
}
.small-font{
	font-size:15px;
}
.remove-flex {
    display: block;
    .col-md-6 {
        display: block;
        flex: unset;
        float: left;
    }
}
.flex-box{
	display:flex;
	justify-content:center;
}

.list-header{
	color:#212529;
	font-weight:600;
	font-size:90%;
}
.list-topic{
	color:#212529;
	font-weight:400;
}
.sameline{
	white-space:nowrap;
}
.published{ //embargo footnote
	font-size:80%;
	font-style:italic;
	padding-bottom:3em;
	margin-top:-0.7rem;
	color: #888;
}

// Colors set

.color-set {
    &-ol {
        background-color: $ol;
		&.main-pulldown-menu .nav-item a.nav-link:hover{
			color:#22E258;
		}
		&.dropdown-menu.show{
			background-color: $ol;
		}
		& .dropdown-item:hover, .dropdown-item:focus{
			background-color: $ol;
			color:#22E258;
		}
        &-bg {
            color: #fff;
            @include bg-rgba($ol, 0.9);
            a {
                color: #fff;
            }
        }
    }
    &-mtb-o {
        background-color: $mtb-o;
		&.main-pulldown-menu .nav-item a.nav-link:hover{
			color:#FFA470;//E59464;
		}
		&.dropdown-menu.show{
			background-color: $mtb-o;
		}
		& .dropdown-item:hover, .dropdown-item:focus{
			background-color: $mtb-o;
			color:#FFA470;//E59464;
		}
        &-bg {
            color: #fff;
            @include bg-rgba($mtb-o, 0.9);
            a {
                color: #fff;
            }
        }
    }
    &-trail-o {
        background-color: $trail-o;
		&.main-pulldown-menu li a.nav-link{
			border-color:#fff;
		}
		&.main-pulldown-menu .nav-item a.nav-link:hover{
			color:#CFD0D3;
		}
		&.dropdown-menu.show{
			background-color: $trail-o;
		}
		& .dropdown-item:hover, .dropdown-item:focus{
			background-color: $trail-o;
			color:#CFD0D3;
		}
		&.navbar-nav-main li a {
			color:#fff;
		}
        &-bg {
            color: #fff;
            @include bg-rgba($trail-o, 0.9);
            a {
                color: #fff;
            }
        }
    }
    &-ski-ol {
        background-color: $ski-ol;
		&.main-pulldown-menu .nav-item a.nav-link:hover{
			color:#1094B5;
		}
		&.dropdown-menu.show{
			background-color: $ski-ol;
		}
		& .dropdown-item:hover, .dropdown-item:focus{
			background-color: $ski-ol;
			color:#1094B5;
		}
        &-bg {
            color: #fff;
            @include bg-rgba($ski-ol, 0.9);
			a {
                color: #fff;
            }
        }
    }
    &-allgemein {
        background-color: $allgemein;
		&.main-pulldown-menu .nav-item a.nav-link:hover{
			color:black;
		}
		&.dropdown-menu.show{
			background-color: $allgemein;
		}
		& .dropdown-item:hover, .dropdown-item:focus{
			background-color: $allgemein;
			color:black;
		}
        &-bg {
            color: #fff;
            @include bg-rgba($allgemein, 0.9);
            a {
                color: #fff;
            }
        }
    }

}

// Other

.badge {
    margin-top: 1px;
    padding: 4px 10px;
    vertical-align: top;

    &-tag {
        background-color: $section-border;
        color: $tag-gray !important;
        font-family: $font-titi !important;
        font-size: 11px !important;
        font-weight: 300;
        margin: 0 4px 6px 0;
        padding: 10px !important;
        text-transform: uppercase;
        &:hover {
            text-decoration: none;
        }
    }
}
.badge-red {
	color:#fff;
	background-color:$red;
}
.badge-gold {
	color:#fff;
	background-color:$gold;
}
.badge-dark-green {
	color:#fff;
	background-color:$dark-green;
}
.badge-orange {
	color:#fff;
	background-color:$orange;
}
.badge-yellow {
	color:#fff;
	background-color:$yellow;
}
.badge-blue {
	color:#fff;
	background-color:$blue;
}
.badge-green {
	color:#fff;
	background-color:$green;
}
.badge-lighten-gray {
	color:#fff;
	background-color:$lighten-gray;
}
.badge-ol {
	color:#fff;
	background-color:$ol;
}
.badge-allgemein {
	color:#fff;
	background-color:$allgemein;
}
.badge-mtb-o {
	color:#fff;
	background-color:$mtb-o;
}
.badge-ski-ol {
	color:#fff;
	background-color:$ski-ol;
}
.badge-trail-o {
	color:#fff;
	background-color:$trail-o;
}
.badge-ardf {
	color:#fff;
	background-color:$ardf;
}

.page-item {
    &.active {
        .page-link {
            background-color: $light-black;
            border-color: $light-black;
        }
    }
    .page-link {
        @include unitize(font-size, 14px);
        @include unitize(line-height, 18px);
    }
}

.author {
    &-img {
        @include border-radius(50%);
    }
}

.share {
    a {
        color: $light-black;
        @include unitize(padding, 0 3px);
    }
}

.nav-prev-next {
    a {
        &.title {
            color: $light-black;
            font-family: $font-roboto;
            font-weight: 700;
            @include unitize(font-size, 13px);
            @include unitize(line-height, 16px);
        }
        &.btn {
            background-color: $btn-light;
            color: $light-black;
            font-family: $font-roboto;
            text-transform: uppercase;
            width: 125px;
            @include unitize(font-size, 11px);
        }
    }
}
.post-link-next {
	color: $light-black;
	font-family: $font-roboto;
	font-weight: 700;
	@include unitize(font-size, 13px);
	@include unitize(line-height, 16px);
}
.pagination{
	padding:1.25rem;
}

#Breadcrumbs {
    font-weight: 400;
    font-size: 14px;
	margin-top:-1.25rem;
    a {
        font-size: 14px;
    }
}

img {
    &.leftAlone {
        @include unitize(margin, 5px 10px 0 0);
    }
	&.blog-icon {
		height:38px;
	}
	&.function-icon{
		height: 30px;
	}
}
p.height-one {
	line-height:1.0;
}
ul {
	list-style-image:url('../img/system/dot-orange.png')
}

//to style MemberprofilePage.ss
.content-container.typography.memberprofile{
	width: 100%;
	border: 1px solid #e3e7ea;
	padding: 1.25rem;
	margin-top: 20px;
	background-color:#fff;
}

// to style lostpassword
#LostPasswordForm_lostPasswordForm .btn{
	margin:10px 0px;
}

// to style login page
#MemberLoginForm_LoginForm .btn{
	margin-bottom: 10px;
	margin-right: 10px;
}
// to style logout page
#LogoutForm_Form .btn{
	margin-bottom: 10px;
	margin-right: 10px;
}
//to style logout link on forum pages
#logoutLink{
	font-size:1rem;
	float:right;
	margin-top: 7px;
}
#logoutLink a:hover{
	text-decoration:none;
}


// Helpers

//@mixin clearfix {
//  &:after {
//    clear: both;
//    content: ' ';
//    display: block;
//  }
//}

//$content-width: 58.75rem;

.content {
	text-align:justify;
//  @include clearfix;
//  margin: 0 auto;
//  max-width: $content-width;
//  padding: 0 0.625rem;
//  width: 90%;
}
//
//@media screen and (max-width: $phone-landscape-max) {
//  .content {
//    padding: 0;
//    width: 100%;
//  }
//
//  section {
//    padding-left: 2.5rem;
//    padding-right: 2.5rem;
//  }
//}
// gray link (Termine)
.gray-link{
	color: $tag-gray;
}

.table.embargo td{
	border-bottom: none;
	border-top:none;
}
.table.embargo th{
	border-bottom:1px solid #dee2e6;
}
