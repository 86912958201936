// Header
// Only define header elements (e.g. header image) here.

header {
    background: $lighten-gray url('../img/bg/header-o-sport229.jpg') center top no-repeat;
    background-size: cover;
	margin-top:20px;
    @include unitize(height, 229px);
	&.centered{
		background-position:center;
	}
	&.position-left{
		backgound-position:left;
	}
	&.header-homepage{
		@include unitize(height, 370px);
	}
    &.header-small {
        @include unitize(height, 190px);
    }
}