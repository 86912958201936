// Nav
// Only define nav elements (e.g. main menu, footer menu) here.

// Main menu
.navbar {
    background-color: $light-gray;

    * {
        transition: all 0.1s ease;
    }

    &-brand {
        background-color: #fff;
        border-bottom-right-radius: 170px;
        color: #000;
        height: 135px;
        position: absolute;
        white-space: inherit;
        width: 300px;
        z-index: 2;
        span {
            line-height: 130%;
        }
        &:active,
        &:hover,
        &:visited {
            color: #000;
        }
    }

    &-nav {
        &-main {
            //background-color: $dark-gray;
            @include unitize(height, 60px);
			height: 2.2rem;
			font-size:0.9rem;

	   
										   
	
			
            li {
                //@include unitize(width, 270px);
                min-width: 8%;
                // min-width: 20%;
                a {
                    color: #fff;
                    @include unitize(line-height, 44px);
                }
            }

            .dropdown-toggle::after {
                display: none;
            }

            .nav-link-icon {
                margin-top: -38px;
            }
        }
    }
    &-row {
        &-top {
			display:flex !important;
            transition: all 0.1s ease;
            @include unitize(height, 45px);
            @include unitize(padding, 5px 0);

            &-clone {
                display: none;
            }
            &-second {
                @include unitize(height, 50px);
            }
        }
    }

    &-sidebar {
        flex: 1;

        &-left {
            background-color: white;
        }
        &-right {
            background-color: $light-gray;
            position: relative;

            .bg-secondary {
                background-color: $dark-green;
                bottom: 0;
                position: absolute;
                width: 100%;
                @include unitize(height, 60px);
            }
        }
    }
/*
    .dropdown-menu {
        border: none;
        border-radius: 0;
        display: none;
        margin: 0;

        &-container {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1000;
                display: block;
                float: left;
                min-width: 10rem;
                padding: .5rem 0;
                margin: 0;
                font-size: 1rem;
                color: #212529;
                text-align: left;
                list-style: none;
                background-clip: padding-box;
                border: none;
                border-radius: 0;
        }
        .subnav {
            //@include unitize(min-height, 450px);
            //@include unitize(max-height, 450px);
            .card-columns {
                @include make-custom-columns(0, $grid-breakpoints);
                .card {
                    background: none;
                    border: none;
                    @include border-radius(0px);
                    @include unitize(padding, 0 0 0 16px);
                }
            }
        }
        &.open-subnav {
            display: block;
        }
        &-subnav {
            h4 {
                font-weight: 700;
                text-transform: uppercase;
                @include unitize(font-size,24);
            }
        }
    }
*/
    &-top-links,
    &-social-links {
		display:flex !important;
		align-items:center;
        a.nav-link {
            color: $top-links-grey;
            padding: 8px 2px;
            text-transform: uppercase;
            @include unitize(font-size, 13px);
            &:hover {
                color: $light-black;
            }
					
													
							
							 
								  
								  
						   
								   
							
											
													
			 
        }
    }
    &-social-links {
        a.nav-link {
            padding: 8px 4px;
            @include unitize(font-size, 17px);
            svg {
                margin-top: 9px;
            }
			img {
				height: 16px;
			}
        }
    }

    // Resize sticky navbar on scroll
    &-shrink {
        .navbar-nav-main {
            @include unitize(height, 40px);
            li a {
                @include unitize(line-height, 24px);
            }
        }
        .navbar-sidebar-right .bg-secondary {
            @include unitize(height, 40px);
        }
    }
}

// Subnav
.subnav {
    &-right {
        border-left: 1px solid $light-gray;
        color: #fff;
        @include unitize(padding-left, 30px);

        .subnav-section {
            color: #fff;
            @include unitize(margin-bottom, 20px);
            &:hover {
                color: #fff !important;
            }
        }
        .text-muted {
            color: #fff !important;
            font-weight: 300;
            @include unitize(font-size, 13px);
        }
        h3 {
            color: #fff;
            font-family: $font-roboto;
            font-weight: bold;
            @include unitize(font-size, 17px);

            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }
        a {
            color: #fff !important;
        }
    }
 /*   .dropdown-item {
        font-weight: 300;
        white-space: normal;
        @include unitize(font-size, 15px);
        @include unitize(line-height, 1.2);
        @include unitize(padding, 4px 0);

        &:last-child {
            @include unitize(margin-bottom, 5px);
        }
        &:hover,
        &:focus,
        &:active {
            background: none;
            color: $light-black;
        }
    }*/
    &-section {
        font-weight: 700 !important;
        text-transform: uppercase;
    }
}

// Inside page nav
.nav-inside {
    a {
        border-bottom: 1px solid $section-border;
        color: #fff;
        font-weight: 700;
        @include unitize(font-size, 15px);
        &:hover,
        &.current {
            color: $link-hover;
        }
        .fa-angle-up,
        .fa-angle-down {
            color: $section-border;
            margin-top: 4px;
        }
        .fa-angle-right {
            color: $section-border;
            margin-right: 4px;
        }
    }
    ul li a {
        font-family: $font-roboto;
        font-weight: 400;
        @include unitize(font-size, 14px);
    }
}

// Scroll to top button
#totop {
    background:#fff;
    border: 1px solid $primary;
    bottom: 20px;
    display: none;
    padding: 0 12px;
    position: fixed;
    right: 20px;
    text-decoration: none;
    transition: all 0.1s ease;
    z-index: 9999;
    @include unitize(font-size, 26px);
}

// hamburger special
.navbar-toggler {
    height: 38px;
    border-color: white;
}
.navbar-toggler .icon-bar {
	width: 22px; 
	height: 2px;
	background-color: #fff;
	display: block;
	transition: all 0.2s;
	margin-top: 4px;
	-webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
/*
.navbar-toggler {
  border: none;
  padding-left:0px;
  margin-left:0px;
  background: transparent !important;
  width:100%;
}

.navbar-toggler a{
	font-weight:normal;
}
*/
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
 
.navbar-toggler .middle-bar {
  opacity: 0;
}
 
.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
 
.navbar-toggler .top-bar {
  transform: rotate(0);
}
 
.navbar-toggler .middle-bar {
  opacity: 1;
}
 
.navbar-toggler .bottom-bar {
  transform: rotate(0);
}
//main menu
.navbar-dark .navbar-nav .nav-link {
	color:white;
}
.navbar-nav-main a.current {
	color:light-black;
}
.navbar-nav-main li {
	min-width:8%;
}
.navbar-nav-main  .main-pulldown-menu li {
	position: relative;
	list-style: none;
	display: block;
	text-align:center;
	list-style-position:inside;
}
.main-pulldown-menu li a {
	display: block;
	margin: 0px;
	padding:0px 10px;
	text-decoration: none;
	line-height: 27px;
	-webkit-transition: color .2s ease-in-out;
	-moz-transition: color .2s ease-in-out;
	-o-transition: color .2s ease-in-out;
	-ms-transition: color .2s ease-in-out;
	transition: color .2s ease-in-out;
}
.main-pulldown-menu li a.nav-link {
	border-left:1px solid #ffffff;
	border-right:1px solid #ffffff;
}
.main-pulldown-menu li:first-child a.nav-link { 
	border-left: none; 
}
.main-pulldown-menu li:last-child a.nav-link{ 
	border-right: none; 
}
.navbar-nav-main .main-pulldown-menu ul {
	min-width:10%;
	list-style-position:inside !important;
	position: absolute;
	top: 37px;
	left: 0px;
	opacity: 0;
	text-transform:none;
	-webkit-transition: opacity .25s ease .1s;
	-moz-transition: opacity .25s ease .1s;
	-o-transition: opacity .25s ease .1s;
	-ms-transition: opacity .25s ease .1s;
	transition: opacity .25s ease .1s;z-index:9999;
}
.navbar-nav-main  .main-pulldown-menu li:hover > ul { 
	opacity: 1; 
}
.navbar-nav-main  .main-pulldown-menu ul li {
	list-style-position:inside;
	text-align:left !important;
	height: 0;
	overflow: hidden;
	padding: 0;
	-webkit-transition: height .25s ease .1s;
	-moz-transition: height .25s ease .1s;
	-o-transition: height .25s ease .1s;
	-ms-transition: height .25s ease .1s;
	transition: height .25s ease .1s;
}
.navbar-nav-main .main-pulldown-menu li:hover > ul li { 
	height: 40px; 
	overflow: visible; 
	padding: 0;width:200px;
}
.navbar-nav-main .main-pulldown-menu ul li a {
	width:200px; 
	padding: 4px 0 4px 4px; 
	margin: 0; 
	border: none; 
}

// main menu in mobile
					  
							  
			
				 
				 
 
											   
				
				 
			   
			
			 
 
						 
						
							
							
							   
										   
										
									  
									   
								  
								  
 
											
				  
						
			
					
 
									   
					
 
											 
				 
 
					 
						
 
							  
						 
 
														   
				 
			 
			
						  
 
									 
						  
 
.dropdown-menu{
	font-size:.9rem;
	padding:.5rem 1rem;
	margin-top: 0;
	border-top: 15px transparent solid;
	border-left:none;
	border-right:none;
	border-radius:0;
}
#navbarDefault>div.navbar-icons{
	display:flex !important;
	flex-basis:auto;
}
#navbarDefault>div.navbar-social-links{
	display:flex !important;
	flex-basis:auto;
}
#navbarDefault>div.navbar-top-links{
	display:flex !important;
	flex-basis:auto;
}
#totalNavbar .navbar-brand{
	color:black;
}

		  
 
a .fas.fa-home{
	font-size:18px;
}
.navbar-row.navbar-top-links.navbar-row-top-second .nav-link.btn-nav{
	width:4rem;
	justify-content:center;
	display:flex;
}
@media screen and (min-width: $tablet-portrait-min) {
	.navbar-row.navbar-top-links.navbar-row-top-second .nav-link.btn-nav{
		width:5.7rem;
	}
}
@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link{ color: #fff;  }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
}	

