// Pages
// Only define various pages elements (e.g. news, clubs listing) here.

.blog-post {
    &-summary {
        font-weight: 700;
    }
}

/* Suche */
#SearchResults h4{
	margin-bottom: 3px;
}
#SearchResults li{
	padding-bottom:12px;
	line-height:1.2;
}
.content-container.searchResults{
	padding:10px 20px;
}

/* Photoswipe */
.pswp--custom {
  --pswp-icon-color: #00a6cc;
  --pswp-icon-color-secondary: #333;
}
.pswp__custom-caption {
  background: #000;
  font-size: 16px;
  color: #fff;
  width: calc(100% - 32px);
  max-width: 400px;
  padding: 2px 8px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  text-align:center;
  transform: translateX(-50%);
}
.pswp--ui-visible .pswp__hide-on-close{
  opacity: 0.8;
}

.pswp__custom-caption a {
  color: #00a6cc;
  text-decoration: underline;
}
.hidden-caption-content {
  display: none;
}

/* dnadesign carousel *********************** */
.carousel_homepage{
	
}

.carousel_homepage .element-carousel__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  margin-top: 0px;
  margin-bottom: 1rem;
}

.carousel_homepage .element-carousel__frame{
	width:100%;
	overflow:hidden;
}
.carousel_homepage .element-carousel__slide{
	position: relative;
	width:100%;
}
.carousel_homepage .element-carousel__slide img{
	display:block !important;
	width: 100% !important;
}

/* Dots */

.carousel_homepage .element-carousel__dots {
	position:absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index:15;
	display: flex;
	justify-content:center;
	padding-left:0;
	margin-right: 15%;
	margin-left: 15%;
	margin-top:0;
	margin-bottom: 1rem;
	list-style:none;
}

.carousel_homepage .element-carousel__dots .active {
	opacity:1;
	background-color:#fff;
}

.carousel_homepage .element-carousel__dots li {
  box-sizing: content-box;
  flex:0 1 auto;
  cursor: pointer;
  border-right: 0;
  border-left: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  background-color:#fff;
  background-clip:padding-box;
  border-radius: 30%;
  height: 4px;
  width: 15px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  opacity: .5;
  transition: opacity 0.6s ease;
}


/* Arrows */

.element-carousel__prev,
.element-carousel__next {
  position: absolute;
  display: block;
  cursor: pointer;
}

.carousel_homepage .element-carousel__next {
  right: 40px;
}

.carousel_homepage .element-carousel__prev {
  left: 40px;
}

.carousel_homepage .element-carousel__next svg,
.element-carousel__prev svg {
  width: 25px;
  color:#fff;
}

/* Site banner ******************************** */
.site-banner p{
	margin-bottom:0px;
	margin-right: 1rem;
}
.site-banner .close{
	margin-top:-1.7rem;
}