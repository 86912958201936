/* ==========================================================================
  Forms
  Only define forms (e.g. input fields, buttons) here.
 ========================================================================== */

.form {
    &-search {
        white-space: nowrap;
		padding-top: 6px;
        .form-control-sm {
            background-color: $pattens-grey;
            border: 1px solid $search-dark-grey;
            outline: none;
            padding-left: 12px;
            @include unitize(min-width, 240px);
            @include border-radius(15px);
        }
        button {
            background: none;
            border: none;
            color: $top-links-grey;
            height: 30px;
            margin-left: -50px;
            padding: 0;
            width: 30px;
        }
    }
}
.form-inline .field.form-control{
		border:none;
		padding:.2rem .2rem;
}
#Form_FilterForm_action_filterData{
	max-width:none;
	margin-left:13px;
	margin-bottom:6px;
}
#Form_FilterArticles_action_showArticles{
	max-width:none;
	/*margin-bottom:3px !important;*/
	margin-left:13px;
	margin-bottom:6px;
}
#Form_FilterForm .dropdown.form-control{
	margin-bottom:5px;
}
#Form_FilterArticles .dropdown.form-control{
	margin-bottom:5px;
}
.form-inline .form-control.form-group--no-label{
	padding:0px 3px 0px 10px;
	height:1.7rem;
	font-size:.8rem;
}
.form-inline .btn-toolbar .btn-sm{
	height:1.7rem;
	line-height:1.1;
}
/* Schwarzes Brett */
#Form_EntryForm_action_postEntry{
	max-width:none;
	margin-bottom:-.2rem !important;
}
.form-vertical .form-control.form-group--no-label{
	padding:0px 3px 0px 10px;
	height:1.7rem;
	font-size:.8rem;
}
.form-vertical .field.form-control{
	border:none;
}
.form-vertical textarea.textarea.form-control.form-group--no-label{
	height:6rem;
}
.form-vertical .form-control{
	display:inline;
}
.mathspamprotector.text.field {
	padding-top:10px;
	padding-bottom:20px;
}
.mathspamprotector.text.form-control{
	width:10em;
}
.checkbox.check-policy{
	margin-right:7px;
	margin-left:1px;
}
.checkbox.check-policy label{
	margin-bottom:0px;
}
.message.bad{
	color:red;
	font-weight:bold;
}
.message.error{
	color:red;
	font-weight:bold;
}
.message.good{
	color:green;
}
.message.required{
	color:red;
	font-weight:bold;
}

/* ClubsPage */
#Form_FilterClubForm fieldset{
	width: 100%;
	display:flex;
	justify-content: center;
}
/* Search Page (FullTextSearch)*/
#SearchForm_getForm input.btn{
	margin-bottom:15px;
}
#SearchForm_getForm fieldset div {
	padding-bottom:10px;
}
#SearchForm_SearchForm button.btn-primary:hover{
	background:none;
}
/* Search Page Google Search */
.card .gsc-selected-option-container{
	word-wrap:normal;
}
/* change password page */
#ChangePasswordForm_ChangePasswordForm_action_doChangePassword{
	margin: 20px 0px;
}
/* newsletter ********************************************************/
#SubscriptionForm_Form label{
	margin-top:1rem;
}
#SubscriptionForm_Form .checkbox label{
	margin-top:0rem;
	margin-bottom:0rem;
}
#SubscriptionForm_Form .btn-toolbar{
	margin-bottom:1rem;
}
#UnsubscribeRequestForm_UnsubscribeRequestForm .btn-toolbar{
	margin:1rem 0rem;
}