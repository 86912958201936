// Variables
// Only define app variables here.

// Layout

$container-max-widths: (
    sm: 480px, // phone landscape viewport width
    md: 768px, // tablet viewport width
    lg: 1024px, // tablet landscape viewport width
    xl: 1260px // 78.75em, desktop viewport width
) !default;

//$container-max-widths: (
//        sm: 540px,
//        md: 720px,
//        lg: 960px,
//        xl: 1140px
//) !default;

// Colors

$blue: #12b0da;
$link-blue: #00a6cc;
$dark-green: #018037;
$green: #18a745;
//$green: #00a637;
//$green: #343a43;
$light-black: #343a43;
$light-gray: #eceff4;
$pattens-grey: #e3e7ea;
$top-links-blue: #2aaacc;
$top-links-grey: #96989c;
$search-dark-grey: #d4e0e0;
$block-header: #494c54;
$block-overlay: #41474f;
$block-text: #0e0d0e;
$forum-text:#212529;
$text-muted: #707680;
$red: #e00000;
$section-border: #555d6b;
$yellow: #cdb511;
$light-yellow:#FFF842;
$pink:#FF7FED;
$orange: #ec671c;
$gold: #d8a900;//#ffca00;
$dark-gray: #393939;
$lighten-gray: #96989c;
$whisper: #eeecee;
$tag-gray: #c5c8ce;
$body-text: #0e0d0e;
$link-hover: #a3b7d5;
$btn-light: #d4dbe6;

$theme-colors: (
    primary: $blue,
    secondary: $light-black,
    success: $green,
    warning: $yellow,
    danger: $red
) !default;

// Sportartfarben
$allgemein:$lighten-gray;
$ol:$green;
$mtb-o:$orange;
$ski-ol:$blue;
$trail-o:$gold;
$ardf:$pink;

// Fonts

$font-family-base: 'Titillium Web', sans-serif !default;

//$dark-blue: #3a3b74;
//$dark-gray: #393939;
//$gray: #d1d1d1;
//$light-black: #1c2022;
//$light-gray: #efefef;
//$lighten-gray: #f3f3f3;
//$white: #fff;
//$color-inactive: #813d00;

// Backgrounds
//$bg-light-gray: #eeeff4;

// Media queries
//$super-ultra-tiny-flip-phone-max: 240px;
//$flip-phone-max: 319px;
$phone-portrait-max: 480px;
$phone-landscape-max: 640px;
$tablet-portrait-min: 641px;
$tablet-portrait-max: 767px;
//$tablet-landscape-min: 769px;
//$tablet-landscape-max: 1024px;
//$desktop-min: 1025px;
//$desktop-max: 1440px;
//$xl-desktop-min: 1441px;
//$xl-desktop-max: 1920px;
//$xxl-desktop-min: 1921px;

// Other
$default-navbar-background:$lighten-gray;
$rem-scale-factor: 16; // used for the `.unitize()` mixin as the scale factor
